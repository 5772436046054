import React, { useContext, useEffect } from "react"
import { BookingForm } from "../../context/context"
import { graphql } from "gatsby"
import ContentArea from "../../dynamic-zone/content-area"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroSection from "../../containers/hero-section-dynamic"
import ContactUs from "../../containers/contact-us"
import BurraLogo from "../../images/burra-logo.png"
import Instagram from "../../containers/instagram"
const Burra = ({ data, location }) => {
  const siteTitle = data.strapiClub.seo.metaTitle || `Title`
  const club = data.strapiClub
  const { setBookingForm } = useContext(BookingForm)
  useEffect(() => {
    setBookingForm({
      open: false,
      type: "Burra Beach House",
      id: 4,
      eventId: 10,
      name: " Burra Beach House",
      resTime: [
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
      ],
    })
  }, [])

  console.log(club)
  return (
    <Layout
      location={location}
      logo={BurraLogo}
      color="bg-euphoriaBlack-50"
      ctaColor="bg-purplebglight-50"
      textCtaColor="text-white"
      clubs
    >
      <Seo title={siteTitle} />
      <HeroSection
        smallImage={club.backgroundImageMobile}
        image={club.backgroundImageMain}
        wheel
        fullScreen
      />
      {/* <CardsEvents
        events={data.allStrapiEvent.edges}
        eventsCarouselBg="#722F8B"
        eventsCarouselFontColor="#FFFFFF"
        bg="bg-peach-50"
        cardBorderColorClass="border-white-50"
        cardFontColorClass="text-white"
        color="#722F8B"
        type={"burra"}
      /> */}
      <ContentArea
        events={data.allStrapiEvent.edges}
        eventsCarouselBg="#722F8B"
        eventsCarouselFontColor="#FFFFFF"
        bg="bg-peach-50"
        noBottomMargin
        bgGallery="bg-peach-50"
        cardBorderColorClass="border-white-50"
        cardFontColorClass="text-white"
        color="#722F8B"
        type={"burra"}
        hideBubble
        galleries={club.galleries}
        textColor="text-purplebglight-50"
        titleColor="text-purplebglight-50"
        cloudColor="text-purplebglight-50"
        cloudBorder="border-purplebglight-50"
        contentTypes={club.pageContent}
        contactColor="bg-euphoriaBlack-50"
        contactHeaderColor="text-purplebglight-50"
        galleryCtaTextColor="text-purplebglight-50"
        galleryCtaBorderColor="border-purplebglight-50"
      />

      {/* <ThreeColumn
        title="The best place on the beach
        for pool, food, and fun."
        subtitle="Beach bar is located in the center of the beach, recognizable by a giant octopus with four faces on the roof of the bar, a DJ stage in the shape of a large fish, and a pool that will surely provide good entertainment in the afternoon and even better fun throughout the night. "
        bg="bg-peach-50"
        textColor="text-purplebglight-50"
      /> */}
      {/* <ContentBlock title="LACE BEACH FOOD" color="bg-euphoriaBlack-50" /> */}
      {/* <Carousel color="bg-euphoriaBlack-50" /> */}
      <ContactUs
        color="bg-peach-50"
        contactHeaderColor="text-purplebglight-50"
        contactSubHeaderColor="text-purplebglight-50"
        contactContentColor="text-purplebglight-50"
        contactCtaColor="text-purplebglight-50"
        contactCtaBorderColor="border-purplebglight-50"
        iframeSrc="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11375.00359087993!2d14.9142232!3d44.5407328!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdaa8ad137a990c34!2sBurra%20Beach%20House!5e0!3m2!1shr!2sba!4v1669142207383!5m2!1shr!2sba"
      />
      {/* <ZrceFun />
      <TwoColumnImages />
      <Restaurants />
      <Cabanas />
      <JetSki /> */}
      {/* <LatestNews /> */}
      {/* <Gallery /> */}
      {/* <Instagram title={"burra_beach_house"} bgColor="#E5B46E" /> */}
    </Layout>
  )
}

export default Burra

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    strapiClub(strapi_id: { eq: 1 }) {
      id
      clubName
      seo {
        metaTitle
        metaDescription
      }
      galleries {
        id
        title
        images {
          id
          localFile {
            childImageSharp {
              id
              gatsbyImageData(quality: 10)
            }
          }
        }
      }
      backgroundImageMain {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
          }
        }
      }
      backgroundImageMobile {
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              formats: WEBP
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
      pageContent {
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_EVENTS {
          id
          sectionId
          strapi_component
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_GALLERY_CLUB {
          id
          strapi_component
          cloudText
          title
          subtitle
        }
      }
    }
    allStrapiEvent {
      edges {
        node {
          slug
          title
          dateTimeFrom(formatString: "DD MMM")
          dateTimeTo(formatString: "DD MMM")
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 20)
              }
            }
          }
        }
      }
    }
  }
`
